import {
    EditorView, keymap, highlightSpecialChars, drawSelection, highlightActiveLine, dropCursor,
    rectangularSelection, crosshairCursor,
    lineNumbers, highlightActiveLineGutter
} from "@codemirror/view"
import {Compartment, EditorState} from "@codemirror/state"
import {
    defaultHighlightStyle, syntaxHighlighting, indentOnInput, bracketMatching,
    foldGutter, foldKeymap, StreamLanguage
} from "@codemirror/language"
import {defaultKeymap, history, historyKeymap} from "@codemirror/commands"
import {searchKeymap, highlightSelectionMatches} from "@codemirror/search"
import {autocompletion, completionKeymap, closeBrackets, closeBracketsKeymap} from "@codemirror/autocomplete"
import {lintKeymap} from "@codemirror/lint"
import {properties} from "@codemirror/legacy-modes/mode/properties"
import {html} from "@codemirror/lang-html"
import {json} from "@codemirror/lang-json"

function fullscreenKeybindings() {
    let compartment = new Compartment();
    let extension = EditorView.editorAttributes.of({ class: "cm-fullscreen" });

    function toggle(view) {
        let active = compartment.get(view.state) == extension
        view.dispatch({
            effects: compartment.reconfigure(active ? [] : extension)
        });
        return true
    }

    function disable(view) {
        let active = compartment.get(view.state) == extension
        if (!active) {
            return false
        }
        view.dispatch({
            effects: compartment.reconfigure([])
        });
        return true
    }
    return [
        compartment.of([]),
        keymap.of([
            {key: 'F11', run: toggle},
            {key: 'Escape', run: disable},
        ])
    ]
}

// https://github.com/codemirror/basic-setup/blob/main/src/codemirror.ts
function basicSetup(config) {
    return [
        config.lineNumbers ? [lineNumbers()] : [],
        [
            EditorState.readOnly.of(config.readOnly),
            highlightActiveLineGutter(),
            highlightSpecialChars(),
            history(),
            foldGutter(),
            drawSelection(),
            dropCursor(),
            EditorState.allowMultipleSelections.of(true),
            indentOnInput(),
            syntaxHighlighting(defaultHighlightStyle, {fallback: true}),
            bracketMatching(),
            closeBrackets(),
            autocompletion(),
            rectangularSelection(),
            crosshairCursor(),
            highlightActiveLine(),
            highlightSelectionMatches(),
            keymap.of([
                ...closeBracketsKeymap,
                ...defaultKeymap,
                ...searchKeymap,
                ...historyKeymap,
                ...foldKeymap,
                ...completionKeymap,
                ...lintKeymap,
            ]),
            fullscreenKeybindings(),
        ],
        config.lineWrapping ? [EditorView.lineWrapping] : [],
    ].flat()
}

export function setupTextArea(textarea, config) {
    let extensions = [basicSetup(config)];
    if (config.lineWrapping) {
    }
    switch (config.language.toLowerCase()) {
        case "properties":
            extensions.push(StreamLanguage.define(properties));
            break;
        case "html":
            extensions.push(html());
            break
        case "json":
            extensions.push(json());
            break;
        default:
            console.error("tried to setup code mirror for unknown language '${language}'")
            return
    }
    if (config.withOnChange) {
        extensions.push(EditorView.updateListener.of(update => {
            if (update.docChanged) {
                textarea.value = view.state.doc.toString()
            }
        }));
    }

    const state = EditorState.create({
        extensions: extensions,
        doc: textarea.value
    })
    const view = new EditorView({
        state,
        parent: textarea.parentNode,
    });

    textarea.parentNode.insertBefore(view.dom, textarea);
    textarea.style.display = "none";

    return view
}